import request from "@/libs/request";

// 证书详情
export function certDetailApi(data) {
  return request({
    url: `/order/order_detail/${data.id}`,
    method: "get",
  });
}
// 证书
export function certExportApi(data) {
  return request({
    url: `/cert/export/${data.cert_id}/${data.key_type}/${data.type}`,
    method: "get",
  });
}
// IIS证书导出申请
export function iisCertDownApi(data) {
  return request({
    url: `/cert/iis_cert_download`,
    method: "post",
    data,
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
    },
  });
}

//  域名删除
export function domainsDeleteApi(data) {
  return request({
    url: `/order/resubmit_cert`,
    method: "post",
    data,
  });
}
// 证书重签
export function certSREsueApi(data) {
  return request({
    url: `/order/reissue_cert`,
    method: "post",
    data,
  });
}
// 重签san超额支付
export function sanPayApi(data) {
  return request({
    url: `/order/pay_san`,
    method: "post",
    data,
  });
}

// SAN订单详情
export function sanOrderDetailApi(data) {
  return request({
    url: `/order/order_san_detail/${data.id}`,
    method: "get",
  });
}
// 查询支付结果
export function checkSanOrderStatusApi(data) {
  return request({
    url: `/order/check_san_order_status`,
    method: "get",
    params: data,
  });
}
