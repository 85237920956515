// src/stores/loginStore.js
import { setCookies } from "@/libs/util";
import { defineStore } from "pinia";
import { AccountLogin } from "@/api/account";
import { Message } from "view-ui-plus";
import router from "@/router";

export const useLoginStore = defineStore("login", {
  state: () => ({
    isLoggedIn: false,
    isModalVisible: 0,
    loginData: {
      account: "",
      password: "",
    },
  }),

  actions: {
    login(loginData) {
      this.loginData = loginData;
      AccountLogin(loginData)
        .then((res) => {
          if (res.status == 200) {
            Message.success(res.msg);
            let expires = this.getExpiresTime(res.data.expires_time);
            setCookies("token", res.data.token, expires);
            setCookies("expires_time", res.data.expires_time, expires);
            setCookies("is_agent", res.data.is_agent, expires);
            this.isLoggedIn = true;
            this.isModalVisible = 0;
            // router.replace({
            //   name: "User",
            // });
          }
        })
        .catch((err) => {
          // this.isModalVisible = 0;
          Message.error(err.msg);
        });
    },
    getExpiresTime(expiresTime) {
      let nowTimeNum = Math.round(new Date() / 1000);
      let expiresTimeNum = expiresTime - nowTimeNum;
      return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24);
    },
    logout() {
      this.isLoggedIn = false;
      this.loginData = {
        account: "",
        password: "",
      };
    },
  },
});
