<template>
  <div>
    <Modal
      v-model="showOrderingModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      :title="title"
      width="720px"
      :styles="{ top: '5px' }"
    >
      <Form
        class="form"
        ref="bulkOrderingForm"
        :model="bulkOrderingData"
        :rules="ulkOrderingRules"
        :label-width="85"
        label-position="left"
      >
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="产品：" prop="bulk_order_store_slug">
              <Select
                v-model="bulkOrderingData.bulk_order_store_slug"
                @on-select="productSelect"
              >
                <Option
                  v-for="(ord, idx) in orderPorductList"
                  :value="ord.store_slug"
                  :key="idx"
                  :label="ord.store_name"
                >
                </Option>
              </Select>
            </FormItem>
            <FormItem label="时长：" prop="bulk_order_periods">
              <Select
                v-model="bulkOrderingData.bulk_order_periods"
                style="width: 200px"
                placeholder="请选择时长"
              >
                <Option
                  v-for="time in buyTimeList"
                  :value="time.name_un"
                  :key="time.name_un"
                >
                  {{ time.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="公司：" v-if="isEVorOV == true" prop="bulk_order_store_slug">
              <Select v-model="bulkOrderingData.bulk_order_company_id">
                <Option
                  v-for="company in companyList"
                  :value="company.id"
                  :key="company.id"
                  :label="company.organization"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="Csr：" prop="cert_type">
              <div class="csr">
                <Select
                  v-model="bulkOrderingData.cert_type"
                  style="width: 150px"
                  placeholder="请选择"
                >
                  <Option value="0" label="RSA"> </Option>
                  <Option value="3" label="ECDSA"> </Option>
                </Select>
                <Select
                  v-model="bulkOrderingData.hash_algorithms"
                  style="width: 150px"
                  placeholder="请选择"
                >
                  <Option value="sha256" label="SHA256"> </Option>
                  <Option value="sha384" label="SHA384"> </Option>
                  <Option value="sha512" label="SHA512"> </Option>
                </Select>
                <Select
                  v-model="bulkOrderingData.keysize"
                  style="width: 150px"
                  placeholder="请选择"
                >
                  <Option value="2048" label="2048"> </Option>
                  <Option value="3072" label="3072"> </Option>
                  <Option value="4096" label="4096"> </Option>
                </Select>
              </div>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="FQDN：" prop="bulk_order_fqdn">
              <Input
                v-model="bulkOrderingData.bulk_order_fqdn"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 15 }"
                style="width: 100%; heigh: 120px"
                placeholder="支持通配符, 一行一单，多域名用“,”（半角）隔开，DCV默认dns，可在每行结尾用“;http”定义"
              />
              <div class="toolBox">
                <Tag @click="showPrefixModal = true">前缀</Tag>
                <Tag @click="prefix('www.')">www.</Tag>
                <Tag @click="prefix('api.')"> api.</Tag>
                <Tag @click="prefix('m.')">m.</Tag>
                <Tag @click="prefix('*.')">*.</Tag>
                <Tag @click="concatRows">合并一行</Tag>
                <Tag @click="unconcatRows">取消合并</Tag>
              </div>
            </FormItem>
            <div class="errorMsg" v-if="errList.length > 0">
              <div class="errlabel">规则提示：</div>
              <div class="errList">
                <div class="errItem" v-for="(item, index) in errList" :key="index">
                  {{ item }}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button
            type="primary"
            size="large"
            :loading="orderLoading"
            @click="submitForm('bulkOrderingForm')"
            >下一步</Button
          >
        </div>
      </template>
    </Modal>
    <Modal
      v-model="showOrderResultModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      title="订单详情"
      width="900px"
    >
      <div class="b-table">
        <div class="table-tip">
          下单成功，私钥可在签发后下载、导出
          <Button type="success" size="large" @click="copy(copySecert)">一键复制</Button>
        </div>
        <Table
          :loading="loading"
          border
          :disabled-hover="true"
          :columns="dcv_info"
          :data="certData"
        >
          <template #dcvExplain="{ row }">
            <div class="dcv" v-if="row.method == 'dns'">
              <div class="dcv-content">
                <span class="copy-title">请添加主机名</span>
                （<span class="copy" @click="copy(row.dns_host)">复制</span>）
                <div class="dns-host">
                  <div class="textBox" v-if="row.subdomain">
                    {{ row.dns_host }}
                  </div>
                  <div class="textBox" v-else>{{ row.dns_host }}</div>
                </div>
              </div>
              <div class="dcv-content">
                <span class="copy-title">类型</span>
                <div class="dns-host">
                  <div class="textBox">{{ row.dns_type }}</div>
                </div>
              </div>
              <div class="dcv-content dcv-foot">
                <span class="copy-title">值</span>（<span
                  class="copy"
                  @click="copy(row.dns_value)"
                  >复制</span
                >）
                <div class="dns-host">
                  <div class="textBox">{{ row.dns_value }}</div>
                </div>
                <span class="copy-title">的DNS记录</span>
              </div>
            </div>

            <div class="dcv" v-else-if="row.method == 'http' || row.method == 'https'">
              <div class="dcv-content">
                <span class="copy-title">请创建</span>（<span
                  class="copy"
                  @click="copy(row[row.method + '_filename'])"
                  >复制</span
                >）
                <div class="dns-host">
                  <div class="textBox">{{ row[row.method + "_filename"] }}</div>
                </div>
              </div>
              <div class="dcv-content">
                <span class="copy-title">内容为</span>（<span
                  class="copy"
                  @click="copy(row[row.method + '_filecontent'])"
                  >复制</span
                >）
                <div class="dns-host">
                  <div class="textBox">
                    {{ row[row.method + "_filecontent"] }}
                  </div>
                </div>
              </div>
              <div class="dcv-content dcv-foot">
                <span class="copy-title">上传到</span>
                <div class="dns-host">
                  <div class="textBox">
                    {{ row[row.method + "_verifylink"] }}
                  </div>
                </div>
                <span class="copy-title">(上传后务必自测是否可访问)</span>
              </div>
            </div>
            <div class="dcv" v-else>
              <div class="dcv-content dcv-foot">
                <span class="copy-title">请进入</span>
                <div class="dns-host">
                  <div class="textBox">{{ row.method }}</div>
                </div>
                <span class="copy-title">邮箱收信</span>
              </div>
            </div>
          </template>
        </Table>
      </div>
      <template #footer>
        <div class="footerBtn">
          <Button type="success" size="large" @click="exportCertFile">一键导出</Button>
          <Button type="primary" size="large" @click="refreshParent">确定</Button>
        </div>
      </template>
    </Modal>
    <Modal
      v-model="showPrefixModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      title="前缀"
      width="520px"
    >
      <Form
        class="form"
        ref="prefixForm"
        :model="prefixData"
        :rules="ruleValidate"
        label-width="90"
        label-position="left"
      >
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="保留原始：">
              <Checkbox label="保留" v-model="prefixData.saveOriginal"> </Checkbox>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="前缀：">
              <Input
                v-model="prefixData.prefixValue"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 7 }"
                style="width: 100%; heigh: 120px"
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="showPrefixModal = false">取消</Button>
          <Button type="primary" size="large" @click="applyPrefix">应用</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  defineOptions,
  defineExpose,
  defineEmits,
  watchEffect,
} from "vue";
import { companyApi, bulkOrderApi, userProductApi } from "@/api/personalCenter";
import { Message, Copy } from "view-ui-plus";
const { proxy } = getCurrentInstance(); // 获取组件实例
const emit = defineEmits(["refresh"]);
const title = ref("批量下单");
let orderPorductList = reactive([]);
let bulkOrderingData = reactive({
  bulk_order_store_slug: "",
  bulk_order_periods: "",
  bulk_export_product_id: "",
  bulk_order_fqdn: "",
  cert_type: "0",
  hash_algorithms: "sha256",
  keysize: "2048",
  bulk_order_company_id: "",
});

let errList = reactive([]);
const ulkOrderingRules = {
  bulk_order_store_slug: [{ required: true, message: "请选择产品", trigger: "blur" }],
  bulk_order_periods: [{ required: true, message: "请选择时长", trigger: "blur" }],
  bulk_export_product_id: [{ required: true, message: "请选择公司", trigger: "blur" }],
  bulk_order_fqdn: [{ required: true, message: "请输入域名", trigger: "blur" }],
};
let certData = reactive([]);
const dcv_info = reactive([
  {
    title: "FQDN",
    key: "domain",
    minWidth: 150,
  },
  {
    title: "说明",
    slot: "dcvExplain",
    minWidth: 600,
  },
]);
const userProduct = () => {
  userProductApi().then((res) => {
    if (res.status == 200) {
      orderPorductList.length = 0;
      orderPorductList.push(...res.data);
    }
  });
};
let buyTimeList = reactive([]);
// -----------操作按钮----------

let showOrderingModal = ref(false); // 下单弹窗
let showOrderResultModal = ref(false); // 下单结果弹窗
let showPrefixModal = ref(false);
let orderLoading = ref(false);
let copySecert = ref("");
let exportCert = ref("");
let isEVorOV = ref(false);
let companyList = reactive([]);

let prefixData = reactive({
  saveOriginal: false, // 是否保留原始
  prefixValue: "",
});
// 打开弹窗
const openModal = () => {
  userProduct();
  showOrderingModal.value = true;
};
const productSelect = (e) => {
  buyTimeList.length = 0;
  isEVorOV.value = false;
  bulkOrderingData.bulk_order_periods = ""; // 选中时提前清空

  // 处理选择产品后返回对应的时间列表
  let abc = "";
  orderPorductList.map((item) => {
    if (item.store_slug == e.value) {
      buyTimeList.length = 0;
      buyTimeList = item.periods_price_txt;
      bulkOrderingData.bulk_order_periods = item.default;
      if (item.validation_level == 1 || item.validation_level == 2) {
        getCompanyList({});
        isEVorOV.value = true;
      }
    }
  });
  // 处理价格为-1的项不显示
  if (buyTimeList != []) {
    let list = [];
    buyTimeList.map((item) => {
      if (item.price != -1) {
        list.push(item);
      }
    });
    buyTimeList = list;
  }
};
function getCompanyList(data) {
  companyApi(data).then((res) => {
    if (res.status === 200) {
      //   console.log(res.data.list);
      companyList.length = 0; // 清空数组
      companyList.push(...res.data.list); // 添加新数据到数组
      bulkOrderingData.bulk_order_company_id = companyList[0].id;
    }
  });
}
const bulkOrder = (data) => {
  bulkOrderApi(data)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.error == null) {
          showOrderResultModal.value = true;
          showOrderingModal.value = false;
          certData.length = 0;
          certData.push(...res.data.return_data);
          copySecert.value = res.data.file_str;
          exportCert.value = res.data.file_path;
          Message.success("下单成功");
          errList.length = 0;
          proxy.$refs.bulkOrderingForm.resetFields();
        } else {
          // Message.error("所选产品不存在");
          errList.length = 0;
          errList.push(...res.data.error);
        }
        orderLoading.value = false;
      }
    })
    .catch((err) => {
      Message.error(err.msg);
      orderLoading.value = false;
    });
};
const copy = (text) => {
  Copy({
    text: text,
  });
};
const exportCertFile = () => {
  window.location.href = `https://api.rjxssl.com/api/download?path=${exportCert.value}`;
};

// 下单弹窗取消按钮
const cancel = () => {
  showOrderingModal.value = false;
  errList.length = 0;
};
const submitForm = () => {
  isEVorOV.value = false;
  proxy.$refs.bulkOrderingForm.validate((valid) => {
    if (valid) {
      orderLoading.value = true;
      bulkOrder(bulkOrderingData);
    } else {
      return false;
    }
  });
};
// 内层应用按钮
const applyPrefix = () => {
  bulkOrderingData.bulk_order_fqdn = addPrefixToLines(
    bulkOrderingData.bulk_order_fqdn,
    prefixData.prefixValue
  );
  showPrefixModal.value = false;
};
const prefix = (prefix) => {
  bulkOrderingData.bulk_order_fqdn = addPrefix(prefix, bulkOrderingData.bulk_order_fqdn);
};
const concatRows = () => {
  bulkOrderingData.bulk_order_fqdn = bulkOrderingData.bulk_order_fqdn.replace(/\n/g, ",");
};
const unconcatRows = () => {
  bulkOrderingData.bulk_order_fqdn = bulkOrderingData.bulk_order_fqdn.replace(
    new RegExp(",", "g"),
    "\n"
  );
};
// 下单结果“确定”按钮
const refreshParent = () => {
  emit("refresh");
  showOrderResultModal.value = false;
};
// ----------------------操作------------------
// 添加自定义前缀
function addPrefixToLines(text, prefix) {
  const lines = text.split("\n");
  // 为每一行添加前缀
  const prefixedLines = lines.map((line) => {
    // 检查前缀末尾是否为 "."
    if (prefix.endsWith(".")) {
      return prefix + line; // 如果是，则直接添加前缀
    } else {
      return prefix + "." + line; // 如果不是，则补上 "." 并添加前缀
    }
  });
  // 是否需要保留原始文本
  if (prefixData.saveOriginal == true) {
    // 合并行
    const prefixedText = prefixedLines.join("\n");
    // 保留原始文本
    return text + "\n" + prefixedText;
  } else {
    // 合并行
    return prefixedLines.join("\n");
  }
}
// 添加指定前缀
function addPrefix(prefix, text) {
  return text
    .split("\n")
    .map((line) => prefix + line)
    .join("\n");
}

defineOptions({
  name: "BulkOrderingModal",
});
watchEffect(() => {});
defineExpose({
  openModal,
  cancel,
  bulkOrderingForm: proxy.$refs.bulkOrderingForm,
});
onMounted(() => {});
</script>
<style lang="less" scoped>
.ivu-modal-body {
  padding: 25px !important;
}

.ivu-select {
  // margin-right: 25px;
  margin-bottom: 5px;
}
.csr{

  display: flex;
  flex-direction: row;
}
.orderingModal .ivu-modal {
  top: 5px !important;
}

.errorMsg {
  display: flex;
  flex-direction: row;
  align-items: center;

  .errlabel {
    width: 70px;
    min-width: 70px;
  }

  .errList {
    color: red;
  }
}

.toolBox {
  .ivu-tag {
    cursor: pointer;
  }
}

.b-table {
  .table-tip {
    margin-bottom: 10px;
  }

  .dcv-content {
    margin-top: 10px;

    .copy-title {
      font-weight: 700;
    }

    .copy {
      color: #337ab7;
      cursor: pointer;
    }

    .dns-host {
      .textBox {
        // margin: 14px 0;
        padding: 14px 10px;
        border-radius: 8px;
        background-color: #f5f5f5;
        border: 1px solid #cecece;
      }
    }
  }
}
</style>
