<template>
  <div class="apiBox">
    <div class="headTitle">获取产品列表.</div>
    <div class="box-item">
      <div class="title">请求</div>
      <div class="content">
        <div class="content-item">协议: https://</div>
        <div class="content-item">
          主机:api.rjxssl.com <span @click="toPages">环境介绍</span>
        </div>
        <div class="content-item">POST：/openapi/product/list</div>
        <div class="content-params">
          <div class="title">参数</div>
          <div class="title">
            <vue-json-pretty :data="paramsData" :path="'root'" :showDoubleQuotes="false">
              <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
              <template #renderNodeKey="{ defaultKey }">
                <Tooltip :content="defaultKey.split('#')[1]" placement="top">
                  {{ defaultKey.split("#")[0] }}
                  <!-- {{ splitString(defaultKey)}} -->
                </Tooltip>
              </template>
            </vue-json-pretty>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="title">响应</div>
      <div class="content">
        <vue-json-pretty :data="responseData" :path="'root'" :showDoubleQuotes="false">
          <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
          <template #renderNodeKey="{ defaultKey }">
            <Tooltip :content="defaultKey.split('#')[1]" placement="top">
              {{ defaultKey.split("#")[0] }}
              <!-- {{ splitString(defaultKey)}} -->
            </Tooltip>
          </template>
        </vue-json-pretty>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const toPages = () => {
  router.replace({
    path: "Overview",
  });
};
let params = {
	"access_key_id#key_id必传":"ii2o50g5*******",
	"access_key_secret#key_secret必传":"ow8ry2hq95wwwck8wc********"
}
let response = {
    "status": 200,
    "msg": "ok",
    "data": [
        {
            "product_id#产品ID，后面下单需要": 25,
            "name#产品名称": "CFCA EV 多域名SSL证书",
            "brand#品牌": "Geotrust",
            "support_wildcard#是否支持通配符": 0,
            "support_san#是否支持添加别名": 1,
            "validation_level#验证级别0=DV,1=OV,2=EV": 2,
            "default_protect_domain#默认域名数": 2,
            "ssl_type#证书类型:0=普通,1=IP证书，2=代码签名,3=混合证书": 0,
            "max_year#最多购买年限": 3,
            "max_domain#最多域名数": 100,
            "pricing#价目表": {
                "periods_price#基础时长价格": {
                    "quarterly_price#季付，-1表示不支持": -1,
                    "annually_price#年付，-1表示不支持": "2000",
                    "biennially_price#两年付，-1表示不支持": "4000",
                    "triennially_price#三年付，-1表示不支持": "6000",
                    "quadrennial_price#四年付，-1表示不支持": -1,
                    "fiveally_price#五年付，-1表示不支持": -1,
                    "six_price#六年付，-1表示不支持": -1
                },
                "san_price#追加SAN价目表": {
                    "quarterly_price": -1,
                    "annually_price": "2000",
                    "biennially_price": "4000",
                    "triennially_price": "6000",
                    "quadrennial_price": -1,
                    "fiveally_price": -1,
                    "six_price": -1
                }
            }
        }
    ]
};
let paramsData = reactive(params);
let responseData = reactive(response);
</script>

<style lang="less" scoped>
.headTitle{
	font-size: 24px;
	font-weight: 600;
}	
.box-item {
  .title {
    font-size: 22px;
    font-weight: 600;
  }
  .content {
    padding: 15px 0;
    .content-item {
      span {
        color: #337ab7;
        cursor: pointer;
      }
    }
    .content-params {
      .title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
