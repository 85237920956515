import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

import Home from "@/views/Home.vue";
import PersonalCenter from "@/views/User/Personal_Center.vue";
import User from "@/views/User/components/user";
import Info from "@/views/User/components/info";
import Balance from "@/views/User/components/balance";
import Cert from "@/views/User/components/cert";
import Order from "@/views/User/components/order";
import Company from "@/views/User/components/company";
import AccessKey from "@/views/User/components/accessKey";
import OrderDetail from "@/views/Order/orderDetail";
import CertDetail from "@/views/Cert/certDetail";
import ProductDetail from "@/views/Product/productDetail";
import Payment from "@/views/Order/payment";
import SanPayment from "@/views/Cert/sanPayment";
import Apply from "@/views/Order/apply";
import Product from "@/views/Product/product";
import Article from "@/views/Article/article";
import Openapi from "@/views/Openapi/openapi";
import Overview from "@/views/Openapi/components/overview";
import ProductList from "@/views/Openapi/components/product_list";
import CertIssue from "@/views/Openapi/components/cert_issue";
import CertReissue from "@/views/Openapi/components/cert_reissue";
import AddSan from "@/views/Openapi/components/add_san";
import CertUpdateDcv from "@/views/Openapi/components/cert_update_dcv";
import CertValidateDcv from "@/views/Openapi/components/cert_validate_dcv";
import CertDeleteSan from "@/views/Openapi/components/cert_delete_san";
import CertRefund from "@/views/Openapi/components/cert_refund";
import CertIssueCallback from "@/views/Openapi/components/cert_issue_callback";
import GenerateCsr from "@/views/Tool/generate_csr";
import DecodeCsr from "@/views/Tool/decode-csr";
import CertConvert from "@/views/Tool/cert_convert";
import DownloadCertChain from "@/views/Tool/download-cert-chain";
import NotFound from "@/components/404";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title:
        "SSL证书,IP证书,https证书,通配符,低价ssl证书,全球知名SSL证书品牌-瑞杰信",
    },
  },
  {
    path: "/home",
    name: "homeAlias",
    component: Home,
    alias: "/",
  },
  {
    path: "/qq",
    name: "homeAlias",
    component: Home,
    alias: "/",
  },
  {
    path: "/Personal_Center",
    name: "Personal_Center",
    meta: {
      title: "个人中心",
    },
    component: PersonalCenter,
    redirect: "/user",
    children: [
      {
        path: "/user",
        name: "User",
        meta: {
          title: "首页",
        },
        component: User,
      },
      {
        path: "/info",
        name: "Info",
        meta: {
          title: "账号管理",
        },
        component: Info,
      },
      {
        path: "/balance",
        name: "Balance",
        meta: {
          title: "我的余额",
        },
        component: Balance,
      },
      {
        path: "/cert",
        name: "Cert",
        meta: {
          title: "我的证书",
        },
        component: Cert,
      },
      {
        path: "/order",
        name: "Order",
        meta: {
          title: "我的订单",
        },
        component: Order,
      },
      {
        path: "/company",
        name: "Company",
        meta: {
          title: "公司管理",
        },
        component: Company,
      },
      {
        path: "/accessKey",
        name: "AccessKey",
        meta: {
          title: "公司管理",
        },
        component: AccessKey,
      },
    ],
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    meta: {
      title: "订单详情",
    },
    component: OrderDetail,
  },
  {
    path: "/sanPayment",
    name: "SanPayment",
    meta: {
      title: "订单支付",
    },
    component: SanPayment,
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      title: "订单支付",
    },
    component: Payment,
  },
  {
    path: "/certDetail",
    name: "CertDetail",
    meta: {
      title: "证书订单详情",
    },
    component: CertDetail,
  },
  {
    path: "/productDetail/:store.html",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/product/:slug.html",
    name: "Product",
    meta: {
      title: "产品选购",
    },
    component: Product,
    props: true,
    beforeEnter: (to, from, next) => {
      const { slug } = to.params;
      next({ path: `/${slug}` });
    },
  },
  {
    path: "/:slug.html",
    name: "ProductBySlug",
    component: Product,
    props: true,
  },
  {
    path: "/apply",
    name: "Apply",
    meta: {
      title: "支付宝支付",
    },
    component: Apply,
  },
  {
    path: "/openapi",
    name: "Openapi",
    component: Openapi,
    redirect: "/overview",
    children: [
      {
        path: "/overview",
        name: "Overview",
        meta: {
          title: "概述",
        },
        component: Overview,
      },
      {
        path: "/product_list",
        name: "Product_list",
        meta: {
          title: "产品列表及成本接口",
        },
        component: ProductList,
      },
      {
        path: "/cert_issue",
        name: "Cert_issue",
        meta: {
          title: "证书下单接口",
        },
        component: CertIssue,
      },
      {
        path: "/cert_reissue",
        name: "Cert_reissue",
        meta: {
          title: "证书重签接口",
        },
        component: CertReissue,
      },
      {
        path: "/add_san",
        name: "Add_san",
        meta: {
          title: "添加SAN接口",
        },
        component: AddSan,
      },
      {
        path: "/cert_update_dcv",
        name: "Cert_update_dcv",
        meta: {
          title: "更改DCV接口",
        },
        component: CertUpdateDcv,
      },
      {
        path: "/cert_validate_dcv",
        name: "Cert_validate_dcv",
        meta: {
          title: "提交检查DCV接口",
        },
        component: CertValidateDcv,
      },
      {
        path: "/cert_delete_san",
        name: "Cert_delete_san",
        meta: {
          title: "删除无法验证的域名接口",
        },
        component: CertDeleteSan,
      },
      {
        path: "/cert_refund",
        name: "Cert_refund",
        meta: {
          title: "退款接口",
        },
        component: CertRefund,
      },
      {
        path: "/cert_issue_callback",
        name: "Cert_issue_callback",
        meta: {
          title: "回调说明",
        },
        component: CertIssueCallback,
      },
    ],
  },
  {
    path: "/article/:slug.html",
    name: "Article",
    component: Article,
    props: true,
  },
  {
    path: "/generate_csr",
    name: "GenerateCsr",
    meta: {
      title: "CSR文件生成工具",
    },
    component: GenerateCsr,
  },
  {
    path: "/cert_convert",
    name: "CertConvert",
    meta: {
      title: "SSL证书格式转换工具",
    },
    component: CertConvert,
  },
  {
    path: "/decode-csr",
    name: "DecodeCsr",
    meta: {
      title: "CSR文件在线验证工具",
    },
    component: DecodeCsr,
  },
  {
    path: "/download-cert-chain",
    name: "DownloadCertChain",
    meta: {
      title: "SSL证书链下载",
    },
    component: DownloadCertChain,
  },
  {
    path: "/404",
    name: "/404",
    component: NotFound,
    meta: {
      title: "404",
    }
  },
  {
    path: "/:pathMatch(.*)",
    //访问主页的时候 重定向到index页面
    redirect: "/404",
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.VITE_PUBLIC_PATH),
  // history: createWebHashHistory(process.env.VITE_PUBLIC_PATH),
  pathMatch: (mode) => (mode === "history" ? /^\/.*\.html?$/ : /^\/.*$/),
  scrollBehavior() {
    return { top: 0 };
  },
});
// 添加全局的 beforeEach 导航守卫
router.beforeEach(async (to, from, next) => {
  if (to.matched.leng === 0) {
    // 如果没有匹配到任何路由，则重定向到 404 页面
    next("/");
  } else {
    // // 否则继续正常的导航
    document.title = to.meta.title || ' ';
    next();
  }
});
export default router;
