<template>
  <div class="home-banner">
    <Carousel autoplay v-model="value" dots="none">
      <CarouselItem v-for="(item, idx) in bannerList" :key="idx">
        <div
          class="carousel-item"
          :class="$store.state.media.isDesktop == true ? 'crsPc' : 'crsPcNotPc'"
          @click="bannerClick(item)"
        >
          <!-- <img src="@/assets/images/banner.png" alt="" /> -->
          <img :src="item.image" alt="轮播图" />
          <!-- <div class="csl">
            <div class="bugTip">现在仅￥<span>2000</span>起</div>
            <div class="more">了解一下</div>
          </div> -->
        </div>
      </CarouselItem>
    </Carousel>
  </div>
</template>

<script setup>
import { homeBannerApi } from "@/api/home";
import {
  ref,
  reactive,
  onMounted,
  defineOptions,
  watchEffect,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const { proxy } = getCurrentInstance(); // 获取组件实例
const store = useStore();
let router = useRouter();
let bannerList = reactive([]);
const homeBanner = () => {
  homeBannerApi().then((res) => {
    bannerList.length = 0; // 清空数组;
    bannerList.push(...res.data.list); // 添加新数据到数组
  });
};
const bannerClick = (e) => {
  console.log(e);
  if (e.type == 1) {
    window.open(e.url, "_blank");
  } else {
    router.push({
      name: "ProductDetailBySlug",
      params: {
        store: e.url,
      },
    });
  }
};
onMounted(() => {
  homeBanner();
});
defineOptions({
  name: "HomeBanner",
});
</script>

<style lang="less" scoped>
.home-banner {
  width: 100%;
  height: 100%;
}
:deep(.ivu-carousel) {
  // height: 330px;
  min-height: auto;
}
.carousel-item {
  scroll-snap-align: start; /* 对齐滚动容器的开始 */
  min-width: 100%; /* 确保每个项目至少占据一屏宽度 */
  min-height: auto; /* 设置最小高度 */
  position: relative;
  cursor: pointer;
  img {
    width: 100%; /* 图片宽度自适应 */
    // height: 200px; /* 图片高度自适应 */
    display: block; /* 移除下方空白 */
  }
  .csl {
    position: absolute;
    z-index: 100;
    bottom: 15%;
    left: 20%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    color: #000;

    .bugTip {
      margin-right: 5px;
      padding: 0px 8px;
      border: 1px solid #000;
      font-size: 12px;
      span {
        font-weight: 800;
        font-size: 18px;
        text-indent: 5px;
      }
    }
    .more {
      background-color: #0178fa;
      color: #fff;
      padding: 5px 12px;
      border-radius: 3px;
      font-size: 12px;
    }
  }
}
.crsPcNotPc{
  img{
    height: 200px;
  }
}
</style>
