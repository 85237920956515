<template>
  <div>
    <Modal
      v-model="showRechargeModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      title="预存"
    >
      <Form
        class="form"
        ref="chargeForm"
        :model="chargeData"
        :rules="ruleValidate"
        label-width="100"
        :label-position="labelPosition"
      >
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="充值金额" prop="amount">
              <Input
                type="text"
                v-model="chargeData.amount"
                placeholder="0.01~10000"
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="充值方式" prop="amount">
              <RadioGroup v-model="chargeData.pay_type">
                <Radio label="wx">
                  <span>微信</span>
                </Radio>
                <Radio label="zfb">
                  <span>支付宝</span>
                </Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <div class="payQrCode" v-if="loading == true">
          <div
            class="codeBox"
            v-if="chargeData.pay_type == 'wx' && store.state.media.isDesktop == true"
          >
            <div class="payType">
              <div class="typeIcon">
                <img src="@/assets/images/WeChatPay.png" alt="" />
              </div>
              <div class="typeName">微信支付</div>
            </div>
            <div :class="chargeData.pay_type == 'wx' ? 'greenBox' : 'blueBox'">
              <div class="code">
                <img
                  id="qr-code"
                  :src="codeLinks"
                  v-if="codeLinks != ''"
                  alt="支付二维码"
                />
                <Spin fix v-else>
                  <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                  <div>Loading</div>
                </Spin>
              </div>
            </div>
          </div>
          <div
            class="codeBox"
            v-if="chargeData.pay_type == 'zfb' && store.state.media.isDesktop == true"
          >
            <div class="payType">
              <div class="typeIcon">
                <img src="@/assets/images/ipayIcon.png" alt="" />
              </div>
              <div class="typeName">支付宝支付</div>
            </div>
            <div :class="chargeData.pay_type == 'wx' ? 'greenBox' : 'blueBox'">
              <div class="code">
                <img
                  id="qr-code"
                  :src="codeLinks"
                  v-if="codeLinks != ''"
                  alt="支付二维码"
                />
                <Spin fix v-else>
                  <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                  <div>Loading</div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button type="primary" size="large" :loading="loading" @click="submitForm"
            >预存</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { rechargeApi, checkYeStatusApi } from "@/api/personalCenter";
import { payCodeApi } from "@/api/order";
import { ref, onMounted, reactive, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
const { proxy } = getCurrentInstance(); // 获取组件实例

import { Message } from "view-ui-plus";
const store = useStore();
let showRechargeModal = ref(false);
let chargeData = reactive({
  amount: null,
  pay_type: "",
  from: "",
});
let order_id = ref("");
let codeLinks = ref("");
let loading = ref(false);
const emit = defineEmits(["refresh"]);

// 获取支付二维码
const payCode = (data) => {
  payCodeApi(data).then((res) => {
    // console.log(res);
    if (res.status == 200) {
      codeLinks.value = res.data.code_path;
    }
  });
};
// 当前支付方式
const currentPay = (pay_type) => {
  stopPolling();
  chargeData.amount = Number(chargeData.amount);
  if (chargeData.pay_type == "wx") {
    chargeData.from = store.state.media.isDesktop == true ? "pc" : "weixinh5";
    // chargeData.from = store.state.media.isDesktop == true ? "pc" : "weixinh5";
    rechargeApi(chargeData).then((res) => {
      if (res.status == 200) {
        order_id.value = res.data.result.order_id;
        if (chargeData.from == "pc") {
          payCode({ text: res.data.result.jsConfig });
        } else {
          // wechatPayMobile(res.data.result.jsConfig);
          window.location.href = res.data.result.jsConfig.mweb_url;
        }
      }
    });
  }
  // 支付宝支付
  if (chargeData.pay_type == "zfb") {
    // chargeData.from = store.state.media.isDesktop == true ? "zfbpc" : "zfbpc";
    chargeData.from = store.state.media.isDesktop == true ? "zfbpc" : "zfbh5";
    rechargeApi(chargeData).then((res) => {
      if (res.status == 200) {
        order_id.value = res.data.result.order_id;
        if (res.data.result.jsConfig) {
          if (chargeData.from == "zfbpc") {
            payCode({ text: res.data.result.jsConfig.qrCode });
          } else {
            document.write(res.data.result.jsConfig);
          }
        }
      }
    });
  }
};


let pollingInterval;
// 微信扫码支付结果轮询
const submitForm = () => {
  currentPay(chargeData.pay_type);
  loading.value = true;
  pollingInterval = setInterval(() => {
    checkYeStatusApi({ order_id: order_id.value })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == true) {
            Message.success("预存成功");
            emit("refresh");
            showRechargeModal.value = false;
            loading.value = false;
            setTimeout(() => {
              stopPolling();
            }, 1000);
          }
        }
      })
      .catch((err) => {
        Message.error("预存失败");
        loading.value = false;
        setTimeout(() => {
          stopPolling();
        }, 1000);
      });
  }, 3000);
};
const stopPolling = () => {
  clearInterval(pollingInterval);
};

const openModal = () => {
  proxy.$refs.chargeForm.resetFields();
  showRechargeModal.value = true;
  chargeData.pay_type = "";
};
const cancel = () => {
  proxy.$refs.chargeForm.resetFields();
  showRechargeModal.value = false;
  chargeData.pay_type = "";
};
// watch(chargeData, (newVal, oldVal) => {
//   console.log("count变化了", newVal, oldVal);
//   if (chargeData.from == "pc" || chargeData.from == "zfbpc") {
//     console.log(typeof(newVal.amount));
//     if (Number(newVal.amount) > 0 && newVal != "") {
//       currentPay(chargeData.pay_type);
//     }else{

//     }
//   }
//   return {
//     chargeData,
//   };
// });

defineOptions({
  name: "RechargeModal",
});
defineExpose({
  openModal,
  cancel,
  stopPolling,
  chargeForm: proxy.$refs.chargeForm,
});
</script>
<style lang="less" scoped>
.payQrCode {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .codeBox {
    width: 350px;
    height: 400px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    .payType {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .typeIcon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .typeName {
        font-size: 16px;
        color: #000;
        font-weight: 400;
      }
    }
    .greenBox,
    .blueBox {
      border-radius: 10px;
      width: 200px;
      height: 200px;
      margin: 30px 0 30px;
      .code {
        width: 180px;
        height: 180px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .demo-spin-icon-load {
          animation: ani-demo-spin 1s linear infinite;
        }
      }
    }
    .greenBox {
      border: 10px solid #43c93e;
    }
    .blueBox {
      border: 10px solid #06b4fd;
    }
  }
}
</style>
