<template>
  <Exception type="404" img-color redirect="/" />
</template>

<script setup></script>

<style lang="less" scoped>
// .notFound {
//   background-color: #fff;
//   display: flex;
//   justify-content: center;
//   height: 700px;
//   align-items: center;
//   flex-direction: column;

//   .imgBox {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     img {
//       width: 350px;
//       height: 350px;
//     }
//   }

//   .tipBox {
//     text-align: center;
//     .tip {
//       font-size: 18px;
//       margin-bottom: 10px;
//       span {
//         color: red;
//         margin-right: 15px;
//       }
//     }
//   }
// }
:deep(.ivu-exception) {
  min-height: 700px !important;
}
</style>
