import request from "@/libs/request";

// 产品列表
export function productListApi(data) {
  return request({
    url: `/get_product_list`,
    method: "get",
    params: data,
  });
}
// 计算价格
export function calculatePriceApi(data) {
  return request({
    url: `/order/price`,
    method: "post",
    data,
  });
}

// 根据slug查询产品id（路由直接跳转）
export function getProductMenuIdApi(data) {
  return request({
    url: `/get_menus_id`,
    method: "get",
    params: data,
  });
}
