<template>
  <div :class="$store.state.media.isDesktop == true ? 'product-Pc' : 'product-notPc'">
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/" class="breadItem"> 首页 </BreadcrumbItem>
        <BreadcrumbItem key="2" to="/product" class="breadItem">
          全部品牌
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content">
      <div class="brandTabs">
        <Tabs v-model="brandActive" @on-click="brandTabsClick">
          <TabPane label="全部品牌" name="0"></TabPane>
          <TabPane
            v-for="brand in brandList.product.brands"
            :key="brand.id"
            :label="brand.name"
            :name="brand.id + ''"
          ></TabPane>
        </Tabs>
      </div>
      <div class="typeTabs">
        <div class="examineTabs">
          <div class="tab-title" v-if="$store.state.media.isDesktop == true">
            按审核要求：
          </div>
          <Tabs v-model="examineActive" @on-click="examineTabsClick">
            <TabPane label="全部" name="0"></TabPane>
            <TabPane
              v-for="sh in brandList.product.sh"
              :key="sh.sh_type"
              :label="sh.name"
              :name="sh.sh_type + ''"
            ></TabPane>
          </Tabs>
        </div>
        <div class="functionTabs">
          <div class="tab-title" v-if="$store.state.media.isDesktop == true">
            按使用功能：
          </div>
          <Tabs v-model="functionActive" @on-click="functionTabsClick">
            <TabPane label="全部" name="0"></TabPane>
            <TabPane
              v-for="gn in brandList.product.gn"
              :key="gn.gn_type"
              :label="gn.name"
              :name="gn.gn_type + ''"
            ></TabPane>
          </Tabs>
        </div>
      </div>
    </div>
    <div class="List">
      <product-card :List="productList"></product-card>
    </div>
  </div>
</template>

<script setup>
import { productListApi, getProductMenuIdApi } from "@/api/product";
import { homeBrandSubmenuApi } from "@/api/home";
import ProductCard from "@/components/productCard.vue";
import {
  ref,
  reactive,
  onMounted,
  watchEffect,
  onBeforeUnmount,
  defineProps,
  watch,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { logoutApi } from "@/api/personalCenter";
import { log } from "mathjs";
const metaTags = getInitialMetaTags();
let router = useRouter();
let route = useRoute();
const brand_id = route.params.brand_id;
const sh_type = route.params.sh_type;
const gn_type = route.params.gn_type;
const props = defineProps(["slug"]);
const { proxy } = getCurrentInstance(); // 获取组件实例

let brandActive = ref("");
let examineActive = ref("");
let functionActive = ref("");
let brandList = reactive({
  product: [],
});
let getData = reactive({
  brand_id: "",
  sh_type: "",
  gn_type: "",
});
const getbrandList = () => {
  homeBrandSubmenuApi().then((res) => {
    Object.assign(brandList.product, res.data);
  });
};
let productList = reactive([]);
function getList(data) {
  productListApi(data).then((res) => {
    if (res.status == 200) {
      productList.length = 0; // 清空数组
      proxy.$translate.execute();
      productList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
// 按品牌
const brandTabsClick = (e) => {
  brandActive.value = e;
  if (e == 0) {
    e = "";
  }
  getData.brand_id = e;

  getList(getData);
};
// 按审核要求
const examineTabsClick = (e) => {
  examineActive.value = e;
  if (e == 0) {
    e = "";
  }
  getData.sh_type = e;
  getList(getData);
};
// 按功能
const functionTabsClick = (e) => {
  functionActive.value = e;
  if (e == 0) {
    e = "";
  }
  getData.gn_type = e;
  getList(getData);
};
function getInitialMetaTags() {
  const title = document.title;
  // const keywords = getMetaContent("keywords");
  // const description = getMetaContent("description");

  // return { title, keywords, description };
  return { title };
}
const getProductMenuId = (data) => {
  getProductMenuIdApi(data).then((res) => {
    if (res.status == 200) {
      if (res.data.brand_id) {
        getData.brand_id  = res.data.brand_id
        brandActive.value = getData.brand_id.toString();
      }
      if (res.data.sh_type) {
        getData.sh_type  = res.data.sh_type
        examineActive.value = getData.sh_type.toString();
      }
      if (res.data.gn_type) {
        getData.gn_type  = res.data.gn_type
        functionActive.value = getData.gn_type.toString();
      }
      getList(getData);
      document.title = res.data.slug + "-SSL证书购买代理";
    }
  });
};
watch(
  () => route.params.slug,
  (newVal, oldVal) => {
    if (newVal) {
      getProductMenuId({ slug: newVal });
    }
  },
  { immediate: true }
);
watchEffect(() => {
  if (brandList.product != {} && brandList.product.brands != []) {
    if (brandActive.value != 0) {
      if (brandList.product.brands) {
        brandList.product.brands.map((item, index) => {
          if (brandActive.value == item.id) {
            document.title = item.name + "-SSL证书购买代理";
          }
        });
      }
    } else {
      document.title = metaTags.title;
    }
  }
});
onMounted(() => {
  getbrandList();
  // if (route.params != {}) {
  //   getData.brand_id = route.params.brand_id != undefined ? route.params.brand_id : "";
  //   brandActive.value = getData.brand_id;

  //   getData.sh_type = route.params.sh_type != undefined ? route.params.sh_type : "";
  //   examineActive.value = getData.sh_type;

  //   getData.gn_type = route.params.gn_type != undefined ? route.params.gn_type : "";
  //   functionActive.value = getData.gn_type;
  // }

  getList(getData);
});
onBeforeUnmount(() => {
  document.title = metaTags.title;
});
</script>

<style lang="less" scoped>
.product-Pc {
  padding: 25px 5% 40px;
  min-height: 700px;
  .routerBox {
    margin-bottom: 15px;
  }
  .content {
    .brandTabs {
      background-color: #fff;
      padding: 0 15px;
      margin-bottom: 25px;
    }
    .typeTabs {
      padding: 20px 15px;
      background-color: #fff;
    }
    :deep(.ivu-tabs-bar) {
      border: none !important;
      margin-bottom: 0;
    }
    :deep(.ivu-tabs-tab) {
      padding: 15px 20px;
    }
    :deep(.ivu-tabs-nav-container) {
      font-size: 16px !important;
    }
    :deep(.ivu-tabs-ink-bar) {
      height: 3px !important;
    }
    :deep(.ivu-tabs-nav-prev),
    :deep(.ivu-tabs-nav-next) {
      line-height: 54px;
    }
    .typeTabs {
      .examineTabs,
      .functionTabs {
        display: flex;
        flex-direction: row;
        .tab-title {
          padding: 15px 20px;
          font-size: 16px;
          color: #000;
        }
        :deep(.ivu-tabs-ink-bar) {
          height: 0px !important;
        }
      }
      .examineTabs {
        border-bottom: 1px dashed #cecece;
      }
    }
  }
  .List {
    margin-top: 40px;
  }
}
.product-notPc {
  padding: 25px 5% 40px;
  min-height: 700px;
  .routerBox {
    margin-bottom: 15px;
  }
  .content {
    .brandTabs {
      background-color: #fff;
      padding: 0 15px;
      margin-bottom: 25px;
    }
    .typeTabs {
      padding: 20px 15px;
      background-color: #fff;
    }
    :deep(.ivu-tabs-bar) {
      border: none !important;
      margin-bottom: 0;
    }
    :deep(.ivu-tabs-tab) {
      padding: 15px 20px;
    }
    :deep(.ivu-tabs-nav-container) {
      font-size: 16px !important;
    }
    :deep(.ivu-tabs-ink-bar) {
      height: 3px !important;
    }
    :deep(.ivu-tabs-nav-scrollable) {
      padding: 0 10px;
    }
    :deep(.ivu-tabs-nav-prev) {
      display: none !important;
    }
    :deep(.ivu-tabs-nav-next) {
      display: none !important;
    }

    :deep(.ivu-tabs-nav-scroll) {
      overflow: scroll !important;
    }
    :deep(.ivu-tabs-nav-scroll::-webkit-scrollbar) {
      height: 0;
    }
    .typeTabs {
      .examineTabs,
      .functionTabs {
        display: flex;
        flex-direction: row;
        .tab-title {
          padding: 15px 20px;
          font-size: 16px;
          color: #000;
        }
        :deep(.ivu-tabs-ink-bar) {
          height: 0px !important;
        }
      }
      .examineTabs {
        border-bottom: 1px dashed #cecece;
      }
    }
  }
}
</style>
