<template>
  <div
    :class="$store.state.media.isDesktop == true ? 'productCard-Pc' : 'productCard-notPc'"
  >
    <div class="paneItemBox" v-if="props.List.length > 0">
      <div
        class="pane-item"
        v-for="(itm, index) in props.List"
        :key="index"
        @mouseenter="showBtn(index)"
        @mouseleave="hiddenBtn(index)"
        @click="goToInfo(itm.store_slug)"
      >
        <div class="pane-item-box">
          <div class="brand_image">
            <img :src="itm.brand_image" alt="" />
            <!-- <img src="@/assets/images/DV.png" alt="" /> -->
          </div>
          <div class="store_name">
            {{ itm.store_name }}
          </div>
          <div class="storeBox" :class="isCnOrEn == true ? 'storeBoxHighter' : ''">
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">支持追加域名：</div>
              <div class="box-value">
                {{ itm.support_san == 0 ? "不支持" : "支持" }}
              </div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">支持通配符：</div>
              <div class="box-value">
                {{ itm.support_wildcard == 0 ? "不支持" : "支持" }}
              </div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">绿色地址栏：</div>
              <div class="box-value">
                {{ itm.support_greenbar == 0 ? "不显示" : "显示 " }}
              </div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">支持小程序：</div>
              <div class="box-value">{{ itm.ssl_type == 2 ? "不支持" : "支持" }}</div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">适用网站：</div>
              <div class="box-value" v-html="itm.ssl_effect "></div>
            </div>
          </div>
          <div class="store-price">
            <div class="annually_price" v-if="itm.ssl_free == 0">
              <span v-if="itm.ssl_type != 3">
                {{ itm.periods_price.ordinary.annually_price }}
              </span>
              <span v-else>
                {{ itm.san_price.ordinary.annually_price }}
              </span>
              元/起
            </div>
            <div
              class="annually_price"
              v-if="itm.ssl_free == 1 && itm.periods_price.ordinary.quarterly_price <= 0"
            >
              <span> 免费 </span>
            </div>
            <div
              class="annually_price"
              v-if="itm.ssl_free == 1 && itm.periods_price.ordinary.quarterly_price > 0"
            >
              <span> {{ itm.periods_price.ordinary.quarterly_price }} </span>
            </div>
            <div class="market_price">市场价{{ itm.market_price }}元/年</div>
            <transition name="slide-down">
              <button
                class="store-buy"
                @click.stop="bugNow(itm, index)"
                v-show="itm.showButton"
              >
                立即购买
              </button>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="emptyBox" v-else>产品上新中···</div>
    <orderDrawer ref="orderDrawerRef"></orderDrawer>
  </div>
</template>

<script setup>
import orderDrawer from "@/components/orderDrawer";
import { ref, reactive, onMounted, defineOptions, defineProps } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
let router = useRouter();
const props = defineProps({
  List: Array,
  default: () => [],
});
let orderDrawerRef = ref(null);

// 列表部分 按钮
const showBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    props.List[index].showButton = true;
  }
};
const hiddenBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    props.List[index].showButton = false;
  }
};
// const isShowInMedia = (index) => {
//   if (store.state.media.isDesktop != true) {
//     props.List[index].showButton = !props.List[index].showButton;
//   }
// };

const goToInfo = (store_slug) => {
  router.push({
    name: "ProductDetail",
    params: {
      store: store_slug,
    },
  });
};
// 立刻购买
const bugNow = (item, index) => {
  orderDrawerRef.value.openDrawer(item);
};
let isCnOrEn = ref(false);
const changeHight = () => {
  const browserLanguages = navigator.languages || [
    navigator.language || navigator.userLanguage,
  ];
  if (browserLanguages[0] == "zh" || browserLanguages[0] == "zh-CN") {
    isCnOrEn.value = false;
  } else {
    isCnOrEn.value = true;
  }
};
onMounted(() => {
  changeHight();
});
defineOptions({
  name: "ProductCard",
});
</script>

<style lang="less" scoped>
.productCard-Pc {
  .paneItemBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    .pane-item {
      min-width: 325px;
      max-width: 440px;
      position: relative;
      text-align: center;
      background-color: #fff;
      // margin-right: 25px;
      margin-bottom: 10px;
      border-radius: 10px;
      overflow: hidden;

      cursor: pointer;
      .brand_image {
        width: 185px;
        height: 32px;
        margin: 20px auto 0;
        // padding: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .store_name {
        color: #0b1e2e;
        font-weight: 800;
        min-width: 270px;
        padding: 5px 10px;
      }
      .storeBox {
        padding: 15px 0 0;
        border-top: 1px solid #edf2f5;
        border-bottom: 1px solid #edf2f5;
        height: 214px;
        margin: 0 15px;
        .box-item-many {
          display: flex;
          flex-direction: row;
          padding: 0 10px 10px;
          justify-content: flex-start;
        }
        .box-item-less {
          display: flex;
          flex-direction: row;
          padding: 0 10px 20px;
          justify-content: flex-start;
        }
        .box-label {
          width: 100px;
          min-width: 100px;
          text-align: right;
          color: #9c9c9c;
        }
        .box-value {
          color: #0b1e2e;
          font-weight: 600;
          width: 138px;
          text-align: left;
          margin-left: 10px;
        }
      }
      .storeBoxHighter {
        height: 348px;
      }
      .store-price {
        padding: 15px;
        .annually_price {
          span {
            font-size: 24px;
            // font-weight: 600;
            color: #f50a26;
            margin-right: 4px;
          }
        }
        .market_price {
          font-size: 12px;
          color: #777;
          text-decoration: line-through;
        }
      }
      .store-buy {
        width: 100%;
        height: 84px;
        line-height: 84px;
        font-size: 22px;
        background-color: #0178f9;
        color: #ffffff;
        z-index: 99;
        text-align: center;
        cursor: pointer;
        position: absolute;
        left: 0;
        border: 0;
        bottom: 0px;
      }
    }
  }

  .emptyBox {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    color: #ccc;
  }
}

// -------------------兼容----------
.productCard-notPc {
  .paneItemBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 25px;
  }
  .pane-item {
    flex: 1;
    // padding: 20px 10px;
    position: relative;
    text-align: center;
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 10px;
    min-width: fit-content;
    overflow: hidden;
    cursor: pointer;
    .brand_image {
      width: 185px;
      height: 32px;
      margin: 20px auto 0;

      // padding: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .store_name {
      color: #0b1e2e;
      // height: 40px;
      // line-height: 40px;
      font-weight: 800;
      padding: 5px 10px;
      min-width: 270px;
    }
    .storeBox {
      padding: 15px 0 0;
      border-top: 1px solid #edf2f5;
      border-bottom: 1px solid #edf2f5;
      height: 214px;
      margin: 0 25px;
      .box-item-many {
        display: flex;
        flex-direction: row;
        padding: 0 10px 10px;
        justify-content: flex-start;
      }
      .box-item-less {
        display: flex;
        flex-direction: row;
        padding: 0 10px 20px;
        justify-content: flex-start;
      }
      .box-label {
        width: 100px;
        min-width: 100px;
        text-align: right;
        color: #9c9c9c;
      }
      .box-value {
        color: #0b1e2e;
        font-weight: 600;
        margin-left: 10px;
        align-items: center;
        text-align: left;
      }
    }
    .storeBoxHighter {
      height: 348px;
    }
    .store-price {
      padding: 15px;
      .annually_price {
        span {
          font-size: 24px;
          // font-weight: 600;
          color: #f50a26;
          margin-right: 4px;
        }
      }
      .market_price {
        font-size: 12px;
        color: #777;
        text-decoration: line-through;
      }
    }
    .store-buy {
      width: 100%;
      height: 84px;
      line-height: 84px;
      font-size: 22px;
      background-color: #0178f9;
      color: #ffffff;
      z-index: 99;
      text-align: center;
      cursor: pointer;
      position: absolute;
      left: 0;
      border: 0;
      bottom: 0px;
    }
  }
  .emptyBox {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #ccc;
  }
}
</style>
