<template>
  <div
    :class="
      $store.state.media.isDesktop == true
        ? 'personal_center-Pc'
        : 'personal_center-notPc'
    "
  >
    <div class="personal_left" v-if="ArticleCategory != []">
      <div class="left-item" v-for="(art, index) in ArticleCategory" :key="art.id">
        <div class="category" @click.stop="getArtList(art, index)">
          <div class="title">{{ art.title }}</div>
        </div>
        <div
          class="article"
          v-for="(artDetail, idx) in art.article"
          :key="artDetail.id"
          :class="{ active: breadCurrent == artDetail.id }"
          @click.stop="getDetail(artDetail, idx)"
        >
          {{ artDetail.title }}
        </div>
      </div>
    </div>
    <div class="personal_right">
      <Breadcrumb separator=">" class="bread" v-if="showContent == 1">
        <BreadcrumbItem to="/" class="breadItem"> 首页 </BreadcrumbItem>
        <BreadcrumbItem class="breadItem"> 文档 </BreadcrumbItem>
        <BreadcrumbItem class="breadItem">
          {{ breadcrumbItemTitle }}
        </BreadcrumbItem>
      </Breadcrumb>
      <Breadcrumb separator=">" class="bread" v-if="showContent == 2">
        <BreadcrumbItem to="/" class="breadItem"> 首页 </BreadcrumbItem>
        <BreadcrumbItem class="breadItem"> 文档 </BreadcrumbItem>
        <BreadcrumbItem
          v-for="(item, idx) in breadcrumbItems"
          :key="idx"
          class="breadItem"
        >
          {{ item.catename }}
        </BreadcrumbItem>
        <BreadcrumbItem
          v-for="(item, idx) in breadcrumbItems"
          :key="idx"
          class="breadItem"
        >
          {{ item.title }}
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider />
      <div class="articleDetail" v-if="showContent == 1">
        <div class="title">{{ breadcrumbItemTitle }}</div>
        <!-- <div class="content" v-html="detail.content"></div> -->
        <div class="artList">
          <div class="list-item" v-for="(it, itx) in artList" :key="it.id">
            <Icon type="ios-link" />
            <div class="item-title" @click="getDetail(it, itx)">{{ it.title }}</div>
          </div>
        </div>
      </div>
      <div class="articleDetail" v-if="showContent == 2">
        <div class="title">{{ detail.title }}</div>
        <div class="content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watchEffect, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { articleListApi, articleDetailApi, articlesApi } from "@/api/article";
let router = useRouter();
// --------左侧菜单处理----------
const ArticleCategory = reactive([]);
const artList = reactive([]);
const breadCurrent = ref(0);
let route = useRoute();
let detail = reactive({});
let breadcrumbItemTitle = ref("");
// let routeQuery = JSON.parse(history.state.obj)
let article_id = route.params.id;
let slug = route.params.slug;
let showContent = ref(0);
const articleList = () => {
  articleListApi().then((res) => {
    if (res.status == 200) {
      ArticleCategory.length = 0; // 清空数组
      ArticleCategory.push(...res.data.list); // 添加新数据到数组
      if (route.query != {}) {
        breadCurrent.value = article_id;
        articleDetail({ slug: slug });
      }
    }
  });
};
const artsList = (data) => {
  articlesApi(data).then((res) => {
    if (res.status == 200) {
      artList.length = 0; // 清空数组
      artList.push(...res.data.list); // 添加新数据到数
    }
  });
};
const articleDetail = (data) => {
  showContent.value = 2;
  articleDetailApi(data).then((res) => {
    if (res.status == 200) {
      Object.assign(detail, res.data.info);
      detail.content = detail.content.replace(
        /<img/gi,
        '<img style="max-width:100%;height:auto;display:inline-block;margin:0px auto;"'
      );
      breadcrumbItems[0] = detail;
      document.title = detail.title;
    }
  });
};
function getDetail(menu, index) {
  breadCurrent.value = menu.id;
  articleDetail({ slug: menu.slug });
  breadcrumbItems[0] = menu;
  let resolved = router.resolve({ name: "Article", params: { slug: `${menu.slug}` } });
  window.history.replaceState({}, document.title, resolved.href);
}
const getArtList = (item) => {
  showContent.value = 1;
  artsList({ cid: item.id });
  breadcrumbItemTitle.value = item.title;
  breadcrumbItems[0] = item;
  document.title = metaTags.title;
  breadCurrent.value = 0
};
const metaTags = getInitialMetaTags();

function getInitialMetaTags() {
  const title = document.title;

  return { title };
}

//----------- 面包屑处理--------
let breadcrumbItems = reactive([]);
// 监听路由变化并更新面包屑
watchEffect(() => {});
// 在组件挂载完成后初始化面包屑
onMounted(() => {
  articleList();
});
</script>

<style lang="less" scoped>
.personal_center-Pc {
  padding: 25px 5% 40px;
  display: flex;
  flex-direction: row;

  .personal_left {
    width: 238px;
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    padding: 25px 0;

    .left-item {
      display: block;
      padding: 0px 10px 15px;
      min-width: 205px;
      text-align: left;
      color: #000;
      font-size: 16px;
    }

    .category {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    .article {
      // text-align: center;
      cursor: pointer;
      padding: 10px 10px;
      white-space: nowrap;
      /*强制内容在一行显示*/
      overflow: hidden;
      /*超出部分溢出*/
      text-overflow: ellipsis;
      /*溢出的部分使用省略号*/
    }

    .active {
      border-right: 4px solid;
      color: #2b85e4;
      background: rgba(0, 120, 249, 0.18);
    }
  }

  .breadItem {
    cursor: pointer;
  }

  .personal_right {
    // margin-top: 8px;
    width: calc(100% - 264px);
    height: 100%;
    min-height: 700px;
    padding: 25px 42px;
    margin-left: 15px;
    background-color: #fff;

    :deep(.ivu-breadcrumb) {
      font-size: 16px;
    }

    :deep(.ivu-divider-horizontal) {
      margin: 12px 0;
    }

    .articleDetail {
      .title {
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        padding: 10px 0;
      }

      .content {
        padding: 15px;
      }
      .artList {
        .list-item {
          display: flex;
          flex-direction: row;
          // justify-content: center;
          align-items: center;
          padding: 10px 0;
          .item-title {
            margin-left: 10px;
            cursor: pointer;
            color: #337ab7;
          }
        }
      }
    }
  }
}

.personal_center-notPc {
  padding: 10px 5% 40px;
  display: flex;
  flex-direction: column;

  .personal_left {
    // width: 238px;
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
    margin-bottom: 24px;

    .left-item {
      display: block;
      padding: 0px 10px 15px;
      width: 100%;
      text-align: left;
      color: #000;

      font-size: 16px;
    }
    .category {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    .article {
      // text-align: center;
      cursor: pointer;
      padding: 10px 10px;
      white-space: nowrap;
      /*强制内容在一行显示*/
      overflow: hidden;
      /*超出部分溢出*/
      text-overflow: ellipsis;
      /*溢出的部分使用省略号*/
    }

    .active {
      border-right: 4px solid;
      color: #2b85e4;
      background: rgba(0, 120, 249, 0.18);
    }
  }

  .personal_right {
    background-color: #fff;
    .articleDetail {
      .title {
        font-size: 21px;
        font-weight: 600;
        text-align: left;
        padding: 10px;
        color: #000;
      }
      .artList {
        .list-item {
          display: flex;
          flex-direction: row;
          // justify-content: center;
          align-items: center;
          padding: 10px 0;
          .item-title {
            margin-left: 10px;
            cursor: pointer;
            color: #337ab7;
          }
        }
      }
      .content {
        padding: 15px;
      }
    }
  }
}
</style>
