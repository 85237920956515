<template>
  <div class="apiBox">
    <div class="headTitle">
      当合作商的订单证书已签发，合作商下单的notify_url将会收到如下请求。合作商在处理成功时应该返回success，否则将每3分钟通知一次,共进行10次尝试。
    </div>
    <div class="box-item">
      <div class="title">请求</div>
      <div class="content">
        <div class="content-item">POST</div>
        <div class="content-params">
          <div class="title">证书回调参数</div>
          <div class="title">
            <vue-json-pretty :data="paramsData" :path="'root'" :showDoubleQuotes="false">
              <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
              <template #renderNodeKey="{ defaultKey }">
                <Tooltip :content="defaultKey.split('#')[1]" placement="top">
                  {{ defaultKey.split("#")[0] }}
                  <!-- {{ splitString(defaultKey)}} -->
                </Tooltip>
              </template>
            </vue-json-pretty>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="title">响应</div>
      <div class="content-item">success</div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const toPages = () => {
  router.replace({
    path: "Overview",
  });
};
let params = {
"type#固定值 cert_issued": "cert_issued",
"order_id#证书订单编号, 下单时返回的订单ID": "wx2316562136516",
"vendor_id#CA订单号码": "9028872629",
"ca_code#PEM格式的证书链代码": "-----BEGIN CERTIFICATE-----MIIGXzCCBEegAwIm3...-----END CERTIFICATE-----",
"cert_code#PEM格式的证书代码":"-----BEGIN CERTIFICATE-----MIIGXzCCBEegAwIm3...-----END CERTIFICATE-----",
"not_before#证书签发时间": "2020-01-01 00:00:01",
"not_after#证书过期时间": "2020-04-01 00:00:01"
};

let paramsData = reactive(params);
// let responseData = reactive(response);
</script>

<style lang="less" scoped>
.box-item {
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .content {
    padding: 15px 0;
    .content-item {
      margin-top: 10px;
      span {
        color: #337ab7;
        cursor: pointer;
      }
    }
    .content-params {
      .title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
