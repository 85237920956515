a
<template>
  <Footer
    class="global-footer"
    :class="
      $store.state.media.isDesktop == true ? 'global-padding-Pc' : 'global-padding-notPc'
    "
  >
    <div
      :class="
        $store.state.media.isDesktop == true
          ? 'footer-content-Pc'
          : 'footer-content-notPc'
      "
    >
      <div class="content-left lines">
        <div class="left-logo">
          <img :src="config.footer_logo" mode="" />
        </div>
        <!-- <div class="left-phoneNumber">
          <Icon type="ios-call" size="27" />
          <div class="number">{{config.phone}}</div>
        </div> -->
      </div>
      <div class="content-center">
        <div class="center-aboutUs">
          <div class="aboutUs-title">关于我们</div>
          <div class="about-list">
            <div class="list-item">认识瑞杰信</div>
            <div class="list-item">公司介绍</div>
            <!-- <div class="list-item">社会招聘</div> -->
          </div>
        </div>
        <div class="center-aboutUs">
          <div class="aboutUs-title">服务</div>
          <div class="about-list">
            <div class="list-item" @click="goToPage('Product')">产品选购</div>
            <div class="list-item">合作专区</div>
            <div class="list-item" @click="goToPage('Article')">资源文档</div>
            <div class="list-item">服务协议</div>
          </div>
        </div>
        <div class="center-aboutUs about">
          <div class="aboutUs-title">联系方式</div>
          <div class="about-list">
            <div class="list-item">
              <div class="item-image">
                <img src="https://static.rjxssl.com/img/dh.png" alt="" />
              </div>
              <div class="item-text" @click="call(config.phone)">
                手机号：{{ config.phone }}
              </div>
            </div>
            <div class="list-item">
              <div class="item-image">
                <img src="https://static.rjxssl.com/img/yj.png" alt="" />
              </div>
              <div class="item-text" @click="send(config.email)">
                邮箱：{{ config.email }}
              </div>
            </div>
            <div class="list-item">
              <div class="item-image">
                <img src="https://static.rjxssl.com/img/kf.png" alt="" />
              </div>
              <!-- <Icon type="ios-mail" size="23" /> -->
              <div class="item-text">
                <a
                  :href="`https://admin.qidian.qq.com/static_proxy/b2b-qq/wpa-link/index.html#/person?uin=${config.tqq}`"
                  target="_blank"
                  >客服: {{ config.tqq }}</a
                >
              </div>
            </div>
            <div class="list-item">
              <div class="item-image">
                <img src="https://static.rjxssl.com/img/wz.png" alt="" />
              </div>
              <div class="item-text">地址：{{ config.address }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right" v-if="$store.state.media.isDesktop == true">
        <div class="qrCodeName">瑞杰信公众号</div>
        <div class="qrCodePic">
          <img src="https://static.rjxssl.com/img/qrcode_for_gh.jpg" alt="" />
        </div>
      </div>
    </div>
    <div
      :class="
        $store.state.media.isDesktop == true ? 'footer-link-Pc' : 'footer-link-notPc'
      "
    >
      <div class="link" v-html="config.footer_link"></div>

      <div class="txt" v-html="config.footer_txt"></div>
    </div>
    <div
      :class="
        $store.state.media.isDesktop == true
          ? 'footer-filings-Pc'
          : 'footer-filings-notPc'
      "
    >
      <div class="filings-left">
        COPYRIGHT ◎2019-2024 {{ config.name }}®. ALL RIGHTS RESERVED.
      </div>
      <div class="filings-right">
        <div class="right-item">
          <!-- <Icon type="ios-mail" size="23" /> -->
          <div class="item-text">
            <a href="https://beian.miit.gov.cn " target="_blank">{{ config.beian }}</a>
          </div>
        </div>
        <div class="right-item">
          <!-- <div class="item-image">
                <img src="@/assets/images/customer.png" alt="" />
              </div> -->
          <!-- <Icon type="ios-mail" size="23" /> -->
          <div class="item-text">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011302000440"
              target="_blank"
              >{{ config.gwab }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </Footer>
</template>

<script setup>
import { homeFooterApi } from "@/api/home";
import { ref, reactive, onMounted, defineOptions } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
let router = useRouter();
let config = reactive({});
const homeFooter = () => {
  homeFooterApi().then((res) => {
    Object.assign(config, res.data.info);
  });
};
const goToPage = (name) => {
  // 点击logo返回首页处理 多端共用
  // if (name == "Home") {
  //   meunActive.value = null;
  //   drawerShow.value = false;
  //   twoLevelDrawerShow.value = false;
  // // }
  if (name == "Product") {
    router.push({
      name: "ProductBySlug",
      params: {
        brand_id: "",
        slug: "all",
      },
    });
  } else {
    router.replace({ name: name });
  }
};
const call = (phoneNumber) => {
  window.location.href = "tel:" + phoneNumber;
};
const send = (email) => {
  window.location.href = "mailto:" + email;
};
// const qqService = (tqq) => {
//   window.location.href = `https://admin.qidian.qq.com/static_proxy/b2b-qq/wpa-link/index.html#/person?uin=${tqq}`;
// };
onMounted(() => {
  homeFooter();
});
defineOptions({
  name: "GlobalFooter",
});
</script>

<style lang="less" scoped>
.global-footer {
  background-color: #0b1e2d;
  color: #9caab5;
}
.footer-content-Pc {
  margin-top: 60px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 60px;
    position: relative;
    .left-logo {
      width: 140px;
      height: 42px;
      margin: 20px 0;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .left-phoneNumber {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      color: #fff;
      background-color: #007bf8;
      padding: 5px 10px;
      border-radius: 3px;
      min-width: 160px;
      max-width: 160px;
      cursor: pointer;
      .number {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
  .lines::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 1px;
    left: 100%;
    height: 80%;
    background-color: #233444;
    transform: translate(-50%, -60%);
  }
  .content-center {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    // margin: 0 35px;

    position: relative;

    .center-aboutUs {
      padding: 0 10% 0 3%;
      min-width: 160px;
      .aboutUs-title {
        font-size: 14px;
        color: #fff;
        margin-bottom: 20px;
      }
      .list-item {
        margin-bottom: 15px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        .item-text {
          margin-left: 8px;
          min-width: 187px;
          a {
            color: #9caab5;
          }
        }
        .item-image {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    // 单独处理联系方式的
    .about {
      .list-item {
        min-width: 70px;
      }
    }
  }
  .content-right {
    display: flex;
    flex-direction: column;
    // padding: 0 15px;
    margin: 0 50px;
    z-index: 10;

    .qrCodeName {
      font-size: 14px;
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
    }
    .qrCodePic {
      width: 120px;
      height: 120px;
      // background-color: #fff;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.footer-link-Pc {
  border-top: 1px solid #233444;
  border-bottom: 1px solid #233444;
  padding: 10px 5px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .link {
    display: flex;
    flex-direction: row;
    // width: 60%;
    .link-title {
      font-size: 14px;
      color: #fff;
      margin-right: 20px;
    }
    :deep(.link-list) {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      .list-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        padding: 0 20px;
        position: relative;
        a {
          color: #9caab5;
        }
      }
    }
  }
  .txt {
    display: none;
  }
  .authentication {
    display: flex;
    flex-direction: row;

    .pic {
      width: 60px;
      height: 20px;
      background-color: #fff;
      margin-left: 10px;
    }
  }
}
.footer-filings-Pc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .filings-right {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    .right-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-right: 5px;

      .item-text {
        margin-left: 5px;
        a {
          color: #9caab5;
        }
      }
      .item-image {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          background-size: object-fit;
        }
      }
    }
  }
}
.text-lines::after {
  display: none;
}
// --------------兼容移动端-------------------
// 底部关于我们
.footer-content-notPc {
  margin-top: 60px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content-left {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    .left-logo {
      width: 140px;
      height: 42px;
      margin: 20px 0;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .left-phoneNumber {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      color: #fff;
      background-color: #007bf8;
      padding: 5px 10px;
      border-radius: 3px;
      min-width: 160px;
      max-width: 160px;
      cursor: pointer;
      .number {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
  .content-center {
    display: flex;
    // width: 60%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin: 0 35px;
    position: relative;
    margin: 10px 0;
    padding: 10px 0;
    border-top: 1px solid #233444;
    // border-bottom: 1px solid #233444;
    .center-aboutUs {
      padding: 0 10px;
      margin-bottom: 10px;
      min-width: 160px;
      .aboutUs-title {
        font-size: 14px;
        color: #fff;
        margin-bottom: 5px;
      }
      .list-item {
        margin-top: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        .item-text {
          margin-left: 8px;
          min-width: 187px;
          a {
            color: #9caab5;
          }
        }
        .item-image {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    // 单独处理联系方式的
    .about {
      .list-item {
        min-width: 70px;
      }
    }
  }
  .lines::before {
    display: none;
  }

  .content-right {
    display: flex;
    flex-direction: column;
    // padding: 0 30px;
    align-items: center;
    justify-items: center;

    .qrCodeName {
      font-size: 14px;
      color: #fff;
      margin-bottom: 20px;
    }
    .qrCodePic {
      width: 120px;
      height: 120px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
// 底部友情链接
.footer-link-notPc {
  border-top: 1px solid #233444;
  border-bottom: 1px solid #233444;
  padding: 10px 5px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .link {
    display: flex;
    flex-direction: column;
    // width: 60%;]
    margin-bottom: 10px;
    .link-title {
      font-size: 14px;
      color: #fff;
    }
    :deep(.link-list) {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;

      .list-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        padding: 0 10px;
        position: relative;
        a {
          color: #9caab5;
        }
      }
    }
  }
  .txt {
    display: none;
  }
  .authentication {
    display: flex;
    flex-direction: row;

    .pic {
      width: 60px;
      height: 20px;
      background-color: #fff;
      margin-left: 10px;
    }
  }
}
.footer-filings-notPc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  .filings-right {
    display: flex;
    flex-direction: column;
    .right-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-right: 5px;
      .item-text {
        margin-right: 5px;
        a {
          color: #9caab5;
        }
      }
      .item-image {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          background-size: object-fit;
        }
      }
    }
  }
}
</style>
