<template>
  <div class="apiBox">
    <div class="headTitle">
      多域名证书、IP地址证书、多域通配符证书订单
      用于移除证书内无法验证的域名，一次仅可以移除一个域名。
      同时，每个订单必须保留主域名，即下单的第一个域名就是主域名，无法全部移除.
    </div>
    <div class="box-item">
      <div class="title">请求</div>
      <div class="content">
        <div class="content-item">协议: https://</div>
        <div class="content-item">主机: api.rjxssl.com</div>
        <div class="content-item">POST：/openapi/remove_domain</div>
        <div class="content-params">
          <div class="title">参数</div>
          <div class="title">
            <vue-json-pretty :data="paramsData" :path="'root'" :showDoubleQuotes="false">
              <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
              <template #renderNodeKey="{ defaultKey }">
                <Tooltip :content="defaultKey.split('#')[1]" placement="top">
                  {{ defaultKey.split("#")[0] }}
                  <!-- {{ splitString(defaultKey)}} -->
                </Tooltip>
              </template>
            </vue-json-pretty>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="title">响应</div>
      <div class="content">
        <vue-json-pretty :data="responseData" :path="'root'" :showDoubleQuotes="false">
          <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
          <template #renderNodeKey="{ defaultKey }">
            <Tooltip :content="defaultKey.split('#')[1]" placement="top">
              {{ defaultKey.split("#")[0] }}
              <!-- {{ splitString(defaultKey)}} -->
            </Tooltip>
          </template>
        </vue-json-pretty>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const toPages = () => {
  router.replace({
    path: "Overview",
  });
};
let params = {
	"access_key_id#key_id必传":"ii2o50g5*******",
	"access_key_secret#key_secret必传":"ow8ry2hq95wwwck8wc********",
	"order_id#必传, 下单时返回的订单ID": 6,
	"domain#必传,域名":"dns.example.com"
};
let response = {
  "status#200代表请求成功": 200,
  "msg": "删除成功"
};
let paramsData = reactive(params);
let responseData = reactive(response);
</script>

<style lang="less" scoped>
.box-item {
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .content {
    padding: 15px 0;
    .content-item {
      span {
        color: #337ab7;
        cursor: pointer;
      }
    }
    .content-params {
      .title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
