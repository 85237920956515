import request from "@/libs/request";

// 生成csr工具
export function generateCsrApi(data) {
  return request({
    url: `/tools/generate_csr`,
    method: "post",
    data,
  });
}
export function decodeCsrApi(data) {
  return request({
    url: `/tools/decode_csr`,
    method: "post",
    data,
    headers: {
      "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
    },
  });
}

// ssl转换工具
export function ssLtransferApi(data) {
  return request({
    url: `/tools/cert_convert`,
    method: "post",
    data,
    headers: {
      "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
    },
  });
}

export function downloadCertChainApi(data) {
  return request({
    url: `/tools/download_cert_chain`,
    method: "post",
    data,
  });
}