<template>
  <div class="apiBox">
    <!-- <div :class="$store.state.media.isDesktop == true ? 'apiBox-Pc' : 'apiBox-notPc'"> -->
    <div class="headTitle">对已签发的证书操作重签.</div>
    <div class="box-item">
      <div class="title">请求</div>
      <div class="content">
        <div class="content-item">协议: https://</div>
        <div class="content-item">主机: api.rjxssl.com</div>
        <div class="content-item">POST：/openapi/certificate/reissue</div>
        <div class="content-params">
          <div class="title">参数</div>
          <div class="title">
            <vue-json-pretty :data="paramsData" :path="'root'" :showDoubleQuotes="false">
              <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
              <template #renderNodeKey="{ defaultKey }">
                <Tooltip :content="defaultKey.split('#')[1]" placement="top">
                  {{ defaultKey.split("#")[0] }}
                  <!-- {{ splitString(defaultKey)}} -->
                </Tooltip>
              </template>
            </vue-json-pretty>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="title">响应</div>
      <div class="content">
        <vue-json-pretty :data="responseData" :path="'root'" :showDoubleQuotes="false">
          <!-- 使用 renderNodeKey 插槽来自定义字段键的渲染方式 -->
          <template #renderNodeKey="{ defaultKey }">
            <Tooltip :content="defaultKey.split('#')[1]" placement="top">
              {{ defaultKey.split("#")[0] }}
              <!-- {{ splitString(defaultKey)}} -->
            </Tooltip>
          </template>
        </vue-json-pretty>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const toPages = () => {
  router.replace({
    path: "Overview",
  });
};
let params = {
  "access_key_id#key_id必传": "ii2o50g5*******",
  "access_key_secret#key_secret必传": "ow8ry2hq95wwwck8wc********",
  "order_id#必传, 下单时返回的订单ID": "wx565206364771583808",
  "csr#必传,客户上传的CSR": "---------BEGIN RSA CERTIFICATE REQUEST---------...",
  "email#必传,联系人邮箱": "admin@rjxssl.com",
  "domains#域名必传": [
    "dns.example.com",
    "http.example.com",
    "https.example.com",
    "email.example.com",
  ],
};
let response = {
  "status#200代表请求成功，其他均为请求失败": 200,
  "msg#当失败时才会出现": "ok",
  "data#数据": {
    create_cert_result: {
      "cost#此订单所花成本": "39.99",
      "order_id#瑞杰信控制台的订单ID,非证书ID": "wx563311284993394226",
      "vendor_id#CA证书ID": "2264453063",
      "dcv_dns_host#DNS记录名称": "_4a940ed95ef3450bc32b16c08914ea20",
      "dcv_dns_type#DNS记录类型": "CNAME",
      "dcv_dns_value#DNS记录的值":
        "51dd94462dc6b16da0dcf2f3a9513b22.af93bed2f04db70a31f4d6027f29a9fd.eOaRJHVj.comodoca.com",
      "dcv_file_name#文件名": "4A940ED95EF3450BC32B16C08914EA20.txt",
      "dcv_file_path#访问链接，CA最终要到此链接验证，添加文件后建议引导用户自行访问":
        "http://example.com/.well-known/pki-validation/4A940ED95EF3450BC32B16C08914EA20.txt",
      "dcv_file_content#文件内容":
        "51dd94462dc6b16da0dcf2f3a9513b22af93bed2f04db70a31f4d6027f29a9fd\ncomodoca.com\neOaRJHVj",
    },
  },
};
let paramsData = reactive(params);
let responseData = reactive(response);
</script>

<style lang="less" scoped>
.box-item {
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .content {
    padding: 15px 0;
    .content-item {
      span {
        color: #337ab7;
        cursor: pointer;
      }
    }
    .content-params {
      .title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
