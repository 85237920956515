<template>
  <div class="apiBox">
  <!-- <div :class="$store.state.media.isDesktop == true ? 'apiBox-Pc' : 'apiBox-notPc'"> -->
    <div class="box-item">
      <div class="title">概述</div>
      <div class="content">
        瑞杰信是专业的 SSL 提供商, 随着有开发能力客户的诉求, 和合作伙伴的呼吁,
        我们隆重推出完整功能的接口 API.
      </div>
	  <div class="content">
	    <!-- <a href="https://apifox.com/apidoc/shared-86c60ba9-1882-4319-9a1e-b1e042e48428/doc-5105032" target="_blank">详细文档点我查看</a> -->
	  </div>
    </div>
    <div class="box-item">
      <div class="title">环境</div>
      <div class="content">
        https://api.rjxssl.com 正式环境, 合作伙伴/代理商应选择正式环境接入线上业务.
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.box-item {
  .title {
    font-size: 24px;
    font-weight: 600;
    
  }
  .content{
    padding: 15px 0;
  }
}
</style>
